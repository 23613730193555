import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './styles/app.style.css';
import './styles/responsive.style.css';
import './styles/app.loader.css';


const output = document.querySelector('#root');
const root = ReactDOM.createRoot(output);
root.render(<App />);
