import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import basedurl from '../../endpoint';

const initialState = {

    isAuthencated: false,
    user: {
        region: '',
        quater: '',
        fullname: '',
        email: '',


    },
    userToken: null,
};


const Auth = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        login(state, action) {
            state.isAuthencated = true;
            state.user = action.payload.user;
            state.userToken = action.payload.token
        },

        assignFields(state, action) {

            state.user.email = action.payload.email;
            state.user.fullname = action.payload.fullname;
            state.user.region = action.payload.region;
            state.user.quater = action.payload.quater;

            sessionStorage.setItem('user', JSON.stringify(state.user));
        },
        getfields(state) {
            return state.user;
        }
    }

});


export const { login, assignFields } = Auth.actions;

export default Auth.reducer;
