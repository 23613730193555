import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: '',
    email: '',
    password: '',
    location: ''

}
const Register = createSlice({
    name: 'registerSlice',
    initialState,
    reducers: {
        emailandname(state, action) {
            state.name = action.payload.name;
            state.email = action.payload.email;
        },

        savelocation(state, action) {
            state.location = action.payload.location;
        },

        saveUsers(state, action) {
            state.password = action.payload.password;
        }
    }
});

export const { emailandname, saveUsers, savelocation } = Register.actions;
export default Register.reducer;