import {
    createBrowserRouter, RouterProvider,
} from "react-router-dom";

import Home from "../pages/home";
import Products from "../pages/products";
import Viewpgae from "../pages/viewpage";
import ProductOverview from "../layout/components/overview";
import Animatepage from "../pages/stores/animatepage";
import Location from "../pages/stores/loginsteps/location";
import Signup from "../pages/stores/loginsteps/Signup";
import Userportal from "../pages/stores/userportal";
import Addproducts from "../pages/stores/products/addproduct";
import UserportalOverview from "../pages/stores/products/ProductOverview";
import PrivateRoutes from "../pages/auth/protectedRoutes/ProtectedRoutes";




const router = createBrowserRouter([
    { path: '/', element: <Home /> },
    { path: '/products', element: <Products /> },
    { path: '/moredetails', element: <Viewpgae /> },

    { path: "/option_select", element: <Animatepage /> },
    { path: '/location', element: <Location /> },
    { path: '/signup', element: <Signup /> },
    {
        path: '/productOverview', element: <ProductOverview />
    }
]);

export const RouteWrapper = () => <RouterProvider router={router} />