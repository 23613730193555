import React, { useState } from 'react';
import styled from 'styled-components';
import logo from '../../images/logo/logo.png';
import StepOne from './loginsteps/stepone';
import Steptwo from './loginsteps/steptwo';
import { useDispatch, useSelector } from 'react-redux';
import animateSlidesSlice, { addsteps, minussteps } from '../../store/theme/animateSlides.slice';
import { useNavigate } from 'react-router-dom';
import StepThree from './loginsteps/stepthree';
import { assignFields } from '../../store/auth/auth.slice';
import { emailandname, saveUsers } from '../../store/auth/register.slice'
export const AnimateWrapper = styled.div`
padding: 3rem;
@media(max-width: 48rem){
width: 99%
padding:15px !importamt;
}

`;



const Animatepage = () => {
    const navigate = useNavigate();

    const step = useSelector((state) => state.animateSlides);
    const [percent, setpercentage] = useState(10);
    const [fullname, setfullname] = useState('');
    const [email, setemail] = useState('');
    const [errorusername, seterrorusername] = useState(false);
    const dispatch = useDispatch();
    const userGlobalstate = useSelector((state) => state.authTheme);
    const userRegistertheme = useSelector(state => state.registertheme);



    const next = () => {
        dispatch(addsteps());


        if (percent == 10) {

            setpercentage((prev) => prev + 40)
        } else {
            
            setpercentage((prev) => prev + 50)


        }

        if (percent >= 100) {


            if (email.trim() == "" || fullname.trim() == "") {
                seterrorusername(true)
            } else {
                seterrorusername(false);
                const payload = { email: email, name: fullname }
                console.log(payload);
                dispatch(emailandname(payload));
                navigate('/location');
            }
        }





    }

    const previous = () => {
        dispatch(minussteps())
        if (percent == 50) {
            setpercentage((prev) => prev - 40);
        } else {
            setpercentage((prev) => prev - 50);
        }



    }

    const change_fullname = (text) => {
        setfullname(text);
    }

    const change_email = (text) => {
        setemail(text)
    }



    return (
        <div className='flex bg-blue-300 h-screen justify-center items-center animate__section_bg'>
            <AnimateWrapper className='bg-white shadow rounded-md  wrapper__linked_wrap '>
                <div className="flex items-center">
                    <div className="grid_logo">
                        <img src={logo} />
                    </div>
                    <div className='font-bold font__h2'>Jabuh</div>
                </div>
                <div className='mt-10'>
                    <div className='bg-gray-200 line__progress rounded-md'>
                        <div className="inner_fill rounded-md bg-black" style={{ width: percent + '%' }}></div>
                    </div>

                    {step.steps == 0 && <StepOne />}
                    {step.steps == 1 && <Steptwo />}

                    {step.steps == 2 &&

                        <>
                            {errorusername && <p className='mt-4 text-red-500 font-medium'>Please enter the following information</p>}

                            <StepThree fullname={fullname} email={email} change_email={(e) => change_email(e.target.value)} change_fullname={(e) => change_fullname(e.target.value)} />
                        </>

                    }




                    <div className='flex gap-3 justify-between mt-40 mydata__btndown'>
                        <div>
                            {step.steps > 0 && <button className='p-2' onClick={previous}> <i className='fa-regular fa-chevron-left'></i> back</button>}
                        </div>
                        <div>
                            <button className='p-2'>skip all</button>
                            <button className='p-2'>skip</button>
                            <button className='p-2 px-3 btn bg-blue-700 rounded-md text-white' onClick={next}>Next</button>
                        </div>
                    </div>
                </div>
            </AnimateWrapper>
        </div>
    )
}

export default Animatepage
