import { configureStore } from "@reduxjs/toolkit";
import submenuSlice from "./theme/submenu.slice";
import animateSlidesSlice from "./theme/animateSlides.slice";
import authSlice from "./auth/auth.slice";
import registerSlice from './auth/register.slice';

const store = configureStore({
    reducer: {
        submenuSlice: submenuSlice,
        animateSlides: animateSlidesSlice,
        authTheme: authSlice,
        registertheme: registerSlice,
    }
});

export default store;