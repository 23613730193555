import React, { useEffect, useState } from 'react'
import Index from '../../layout/components/Navigation';
import MyCard from '../../layout/components/store/SubcatTitle';
import { Container } from '../../layout/components/styles';
import Search from '../../layout/components/search';
import Footer from '../../layout/components/footer/footer';
import homeimg from '../../images/category/house.webp';
import gadgetsimg from '../../images/category/phones.webp';
import cartimg from '../../images/category/cart.webp';
import hobbies from '../../images/category/hobbies.webp';
import jobsimg from '../../images/category/jobs.webp';
import Electronicsimg from '../../images/category/electronicm.webp';
import Cartone from '../../layout/components/products/cartone';
import { useDispatch, useSelector } from 'react-redux';
import { isopen } from '../../store/theme/submenu.slice';
import axios from 'axios';

function replace_whitespaces(data) {
    var name = data.replace(/\s+/g, "_");
    return name;
}





const Home = () => {
    const n1 = localStorage.getItem('category') ? JSON.parse(localStorage.getItem('category')) : [];
    const n2 = localStorage.getItem('subcategory') ? JSON.parse(localStorage.getItem('subcategory')) : [];
    const [category, setcategory] = useState(n1);
    const [subcategory, setsubcategory] = useState(n2);
    const [filtered, setfilter] = useState([]);

    useEffect(() => {
        axios.get('productcategory/index').then((res) => {


            localStorage.setItem('category', JSON.stringify(res.data.data));
            setcategory(res.data.data);
        });

        axios.get('productcategory/sub/index').then((res) => {
            setsubcategory(res.data.data);
            localStorage.setItem('subcategory', JSON.stringify(res.data.data));

        });
    }, [])



    const dispatch = useDispatch();
    const submenuTheme = useSelector((state) => state.submenuSlice);
    const showsubmenu = (name) => {


        dispatch(isopen());
        var results = category.filter((category) => category.name.toLowerCase() == name.toLowerCase());
        setfilter(results);
        console.log(results[0]);


    }





    return (
        <div>
            <Index />

            <Container>



                <div className='mt-5 justify-center gap-5 flex  menu___wrapper_morethan mobile_components'>

                    {category.map((cat) => {
                        return <>

                            <MyCard img={cat.image} name={cat.name} onclick={() => showsubmenu(cat.name)} />
                        </>
                    })}

                    {submenuTheme.isopen == true && <div className="fixed flex items-center justify-center overlay__menu__group">
                        <div className="fixed   bg-white rounded  over__lay__menu">
                            <div className="header__banner justify-between bg-blue-700 gap-2 flex items-center text-white px-2">

                                <div className='flex gap-2'>
                                    <i className={`fa-regular ${filtered[0].image}`}  style={{ fontSize: 15 }}></i>
                                    {filtered[0].name}
                                </div>

                                <div className="icon__close__menu rounded-full flex justify-center items-center" onClick={() => {
                                    dispatch(isopen());
                                }}>
                                    <i className='fa-regular fa-close'></i>
                                </div>
                            </div>
                            <div className='over__menu___'>
                                {filtered[0].subcategories.map((sub) => (
                                    <a href={"/products/" + replace_whitespaces(sub.name)} className='p-2 block font-medium font-gray-800'>{sub.name}</a>
                                ))}
                            </div>
                        </div>
                        <div className="overlay  fixed bg-black bg-opacity-10"></div>
                    </div>}




                </div>

                <div className=' p-5  desktop__data__underlined '>

                    <h4 className='font-bold heading_category ml-10 '>Popular Catgories</h4>
                    <div className='flex flex-wrap justify-center   gap-5 mt-5'>
                        {category.map((cat) => (

                            <div className='my_menu_class bg-gray-200 p-3 rounded'>

                                <div className='flex items-center text-blue-700'>

                                    <i className={`fa-regular ${cat.image}`} style={{ fontSize: 17, }}></i>
                                    <div className='font-bold ml-2 font__bolder '>{cat.name}</div>
                                </div>


                                <div>
                                    <div className='submenu__container_lg'>
                                        {subcategory.map((subcat) => {
                                            if (subcat.parentCategory === cat.id) {
                                                return <div className='mt-1'>
                                                    <a className='transition-colors duration-300 ease-in-out hover:text-red-500' href={"/products/" + replace_whitespaces(subcat.name)}>{subcat.name}</a>
                                                </div>
                                            }
                                        })}
                                    </div>
                                    <a href='/product' className='text-blue-800 font-medium block'>All In {cat.name} <i className='fa-regular fa-arrow-right'></i> </a>
                                </div>
                            </div>
                        ))}

                    </div>

                </div>


            </Container>


            <Footer />
        </div>
    )
}

export default Home;
