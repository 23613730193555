import React, { useState } from 'react';
import styled from 'styled-components';
import { Tree, Button, Select, Input } from 'antd';
import Inputs from '../components/Inputs';
const Header = styled.div`
height: 50px;


`;
const Dasboard = styled.div``;
const Sidebarinner = styled.div`
width: 90%;


`;
const Sidebar = styled.div`
width: 250px;

@media(max-width: 48rem){
display: none;
}
`;
const MenuSidebar = styled.div`
span, i{
font-size: 14px;
}
`;

const MobileSection = styled.div`
height: 55px;
display: none;

@media(max-width: 48rem){
display: flex;
}

`;

const ContentDisplay = styled.div``;



const Userportal = () => {
  const [loadding, setloading] = useState(false);

  const [price_f, setprice_f] = useState('0');
  const [costprice_f, setcostprice_f] = useState('0');

  const [price, setprice] = useState(0)
  const [costprice, setcostprice] = useState(0);
  const [margin, setmargin] = useState(0);
  const [profit_f, setprofit_f] = useState("");

  const { Option } = Select;

  const format_price_1 = (e) => {
    let value = e.target.value;
    console.log(value)
    value = value.replace(/,/g, '');
    value = value.replace(/\D/g, '');
    setprice(Number(value))
    value = Number(value).toLocaleString();
    setprice_f(value);

  }
  const format_price_2 = (e) => {
    let value = e.target.value;
    console.log(value)
    value = value.replace(/,/g, '');
    value = value.replace(/\D/g, '');
    const newvalue = Number(value)

    value = Number(value).toLocaleString();
    setcostprice_f(value);

    setcostprice(newvalue)

  }

  function calculate_proift() {
    const total = price - costprice;
    const percentage = total / 100;

    setprofit_f(total.toLocaleString());
    setmargin(percentage);


  }





  return (

    <div className="header_portal__ui flex flex-col  h-screen">
      <Header className='flex justify-between items-center header_section_top_section_desktop bg-blue-900'>
        <div className="logo flex justify-center font_custome_Linque capitalize text-white font-extrabold items-center">
          Jabuh
        </div>
        <div className="rounded-lg flex items-center  search_section">
          <div className="search_Icon flex items-center justify-center ">
            <i className='fa-solid fa-search text-gray-400'></i>
          </div>
          <input type="text" placeholder='Search' />
          <div className='flex gap-1 items-center mx-3'>
            <div className="icon__shortcut bg-gray-800 text-gray-400 font-medium rounded-md">Ctrl</div>
            <div className="icon__shortcut bg-gray-800 text-gray-400 font-medium rounded-md">J</div>
          </div>
        </div>

        <div className=" flex items-center  gap-3 mr-4 user_option_components_avatar">
          <div className=" hover:bg-gray-300 flex cursor-pointer u_highlights rounded-md">
            <div className="icon_u relative p-1 px-2 ">

              <div className="absolute bg-red-600 flex justify-center items-center rounded-full text-white font-medium icon_badge">
                1
              </div>

              <i className='fa-regular fa-bell text-white' style={{ fontSize: 17 }}></i>
            </div>


          </div>

          <div className="avatar cursor-pointer u_highlights  rounded-lg custome_ouliune flex px-1 py-1  items-center gap-2 ">
            <div className="icon__shopdata rounded-lg bg-red-600 flex    justify-center items-center">
              MS</div>
            <span className='text-white font-medium'> My Store</span>
          </div>
        </div>
      </Header>

      <MobileSection className='bg-blue-900 gap-3 items-center'>
        <div className=' flex items-center justify-center cursor-pointer ml-2 mini___icon rounded-md'>
          <i className='fa-regular fa-bars text-white ' style={{ fontSize: 22 }}></i>
        </div>

        <div className='flex rounded-md search__input___data '>
          <div className="flex items-center justify-center icon__md">
            <i className='fa-solid fa-search text-white' style={{ fontSize: 16 }}></i>
          </div>
          <input type="text" className='px-2' style={{ backgroundColor: 'transparent' }} />
        </div>

        <div className='flex'>
          <div className="  icon__bell_mobile  mr-2 flex  justify-center items-center rounded-md">
            <i className='fa-regular fa-bell text-white' style={{ fontSize: 20 }}></i>
          </div>

          <div className="icon__bell_mobile bg-red-600  mr-2 flex  justify-center items-center rounded-md">
            MS
          </div>
        </div>
      </MobileSection>



      <Dasboard className='flex flex-1 '>
        <Sidebar className=' sidebar__dashabord'>
          <Sidebarinner className='pt-3 mx-auto'>
            <MenuSidebar href='/userportal' className='flex gap-2 py-1 mt-2 items-center cursor-pointer rounded-md active__sidemneu transition_'>
              <i className='fa-solid fa-home ml-3'></i>
              <span className='font-medium '>Home</span>
            </MenuSidebar>
            <MenuSidebar href='/order' className='flex mt-2 gap-2 py-1 items-center cursor-pointer rounded-md menu_sidebar  transition_'>
              <i className='fa-solid fa-tag ml-3'></i>
              <span className='font-medium '>Orders</span>
            </MenuSidebar>
            <MenuSidebar href='/userportal_productoverview' className='flex mt-2 gap-2 py-1 items-center cursor-pointer rounded-md menu_sidebar  transition_'>
              <i className='fa-solid fa-inbox ml-3'></i>
              <span className='font-medium '>Product</span>
            </MenuSidebar>
          </Sidebarinner>
        </Sidebar>

        <ContentDisplay className='flex ContentDisplay flex-col  flex-1'>

          <div className="container__wrapper_main_group">
            <div className='mx-auto container__wrapper'>
              <div className='flex mt-5  justify-between'>
                <div className='font-bold add_product_text'> <i className='fa-regular fa-arrow-left' style={{ fontSize: 17 }}></i> Add Product</div>
                <div className='p-2 bg-gray-200 view__container cursor-pointer  rounded-sm flex items-center justify-center'>View</div>
              </div>

              <div className='flex gap-4 w-full paragraph_container'>
                <div className='w-full'>
                  <div className='p-4 rounded-md bg-white w-full shadow'>
                    <div className='form_group_section_wrap w-full'>
                      <div className='lable_fonts_fonts '>Title</div>
                      <Inputs inputplaceholder={false} styles={{ width: "100%" }}
                        instyles={{ borderTopLeftRadius: "inherit", borderBottomLeftRadius: "inherit" }}
                      />
                    </div>


                    <div className='form_group_section_wrap mt-5  w-full'>
                      <div className=' font__title lable_fonts_fonts '>Description</div>

                      <div className='user_select_input textarea__'>

                        <div className='bg-gray-200 flex  control__data'>
                          <div className='flex items-center px-2 '>Paragraph <div className='ml-2'>
                            <i className='fa-regular fa-chevron-down'></i>
                          </div> </div>
                          <div className='flex controls__group '>
                            <div className='para_icons flex items-center justify-center font-bold'> B  </div>
                            <div className='para_icons flex items-center justify-center italic'> <i className='fa-regular fa-italic'></i> </div>
                            <div className='para_icons flex justify-center items-center '> <i className='fa-regular fa-underline'></i> </div>
                            <div className='para_icons'> <i className='fa-regular fa-A'></i></div>
                            <div className='para_icons flex  items-center'>
                              <i class="fa-regular fa-align-left"></i>
                              <i class="fa-regular fa-chevron-down" style={{ fontSize: 12, marginLeft: 3 }}></i>
                            </div>
                          </div>
                        </div>

                        <textarea type="text" className='w-full outline-none  border-none    rounded-md p-3 ' >
                        </textarea>
                      </div>

                      <div className='form_group_section_wrap mt-4 w-full'>
                        <div className='lable_fonts_fonts '>Category</div>
                        <Inputs inputplaceholder={false} styles={{ width: '100%' }}
                          instyles={{ borderTopLeftRadius: "inherit", borderBottomLeftRadius: "inherit" }}

                        />
                        <div className='lable_fonts_fonts'>Determines tax rates and adds metafields to improve search, filters, and cross-channel sales</div>
                      </div>

                    </div>





                  </div>
                  <div className='p-4 rounded-md mt-5 bg-white w-full shadow'>
                    <div className='font-bold'>Pricing</div>

                    <div className='flex mt-3'>
                      <div className='form_group_section_wrap '>
                        <div className='lable_fonts_fonts '> Price</div>

                        <Inputs onchange={format_price_1} value={price_f} />

                      </div>


                    </div>

                    <div className='flex mt-5 gap-4'>
                      <div className='form_group_section_wrap w-full'>
                        <div className='lable_fonts_fonts  '>Cost Price</div>
                        <Inputs onchange={format_price_2} onkeyup={calculate_proift} value={costprice_f} />
                      </div>
                      <div className='form_group_section_wrap w-full'>
                        <div className='lable_fonts_fonts  '> Profit</div>
                        <Inputs onchange={format_price_2} disabled={true} value={profit_f} />
                      </div>



                      <div className='form_group_section_wrap w-full'>
                        <div className='lable_fonts_fonts '>Margins</div>
                        <Input type="text" className='border border-gray-500 rounded-md px-2 custome__input' value={margin + "%"} />
                      </div>


                    </div>
                  </div>


                </div>


                <div className="price__content">
                  <div className='shadow p-4 bg-white rounded  image__wrapper_content'>
                    <div>Status</div>
                    <div class="mt-3  flex items-center justify-center">
                      <Select


                        defaultValue='active'
                        className='w-full custome__input' >
                        <Option value='Active'>Active</Option>
                        <Option value='Draft'>Draft</Option>

                      </Select>


                    </div>
                  </div>
                  <div className='shadow mt-4 p-4 bg-white rounded  image__wrapper_content'>
                    <div>Status</div>
                    <div class="mt-3  flex items-center justify-center images__outlined__group">
                      <button>add Image</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContentDisplay>
      </Dasboard>

    </div >
  )
}

export default Userportal
