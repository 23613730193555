import React, { useState, useEffect } from 'react';
// import logo from '../../../../images/logo/logo.png';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import apple from '../../images/logo/apple_logo.png';
import google from '../../images/logo/google.png';
import meta from '../../images/logo/meta.png';
import Cookies from 'js-cookie'
import { clearAllCookies } from '../../App';
import { login } from '../../store/auth/auth.slice';


async function getAuth(user, token) {
    const payload = {
        user: user,
        token: token
    }

    return payload;
}

const Login = () => {
    const userTheme = useSelector(state => state.registertheme);
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [email, setemail] = useState(userTheme.email || '');

    const [password, setpassword] = useState('');
    const [passwordconfirmation, setpasswordconfirmation] = useState('');
    const [error, seterror] = useState(false);
    const [success, setsuccess] = useState(false);
    const [errormessage, seterrormessage] = useState('Something went wrong');
    const [showpassword, setshowpassword] = useState(false);

    //user effect
    useEffect(() => {
        // Check if the user is already logged in
        const token = Cookies.get('token'); // Check if the token exists in cookies
        if (token) {
            // If the token exists, redirect to the dashboard
            navigate('/userportal_productoverview'); // Replace with your dashboard route
            return
        }
    }, [navigate]);









    const [validator, setvalidator] = useState({});
    const [loading, setloading] = useState(false);

    const submitform = () => {
        seterror(false);
        setloading(true)
        const formdata = new FormData();
        formdata.append('email', email);
        formdata.append('password', password);
        ;


        axios.post('auth/user_login', formdata).then((respond) => {
            console.log(respond.status)
            if (respond.status == 201 || respond.status == 200) {
                const { access_token, expires_in, token_type, user } = respond?.data;
                console.log(respond)
                Cookies.set('token', access_token, { expires: expires_in / (24 * 60 * 60) });
                Cookies.set('user', JSON.stringify(user));
                getAuth(user, access_token).then((payload) => {

                    dispatch(login(payload));

                })
                // console.log(access_token)

                setsuccess(true);
                setloading(false);
                setTimeout(() => {
                    navigate('/userportal_productoverview');
                }, 2000);


            }
        }).catch((err) => {


            clearAllCookies();
            setloading(false)

            if (err.response.status == 400) {
                setvalidator(err.response.data.validator)
            }
            if (err.response.status == 401) {
                seterror(true);
                setsuccess(false)
                seterrormessage(err.response.data.message)
            }
            if (err.response.status == 500) {
                seterror(true);
                setsuccess(false)
                seterrormessage(err.response.data.message)
            }

        });

    }


    return (
        <div className='flex bg-blue-300 h-screen justify-center items-center animate__section_bg'>
            <div className='bg-white shadow p-10 rounded-md mx-auto content_signUp '>
                <div className='content_signUp__innner mx-auto'>
                    {success && <div className='bg-green-500 text-center p-2 rounded-md text-white my-3'>
                        Login Successfull <i className='fa-solid fa-check-circle ml-3'></i> </div>}


                    {error &&
                        <div className='bg-red-500 text-center p-2 rounded-md text-white my-3'>{errormessage}</div>}

                    <div className="flex items-center">
                        <div className="grid_logo">
                            {/* <img src={logo} /> */}
                        </div>
                        <div className='font-bold font__h2'>Jabuh</div>
                    </div>




                    <div className=' mt-4'>
                        <h1 className='font-bold font__h1_big line_height_h1 '>Login </h1>
                        <p className='mt-1'>to continue</p>


                        <div className='mt-5'>
                            <label htmlFor="" className='region__section  capitalize'>email</label>
                            <input type="email"
                                onChange={(e) => setemail(e.target.value)}
                                value={email}
                                className='font-control pl-2 bg-gray-100 mt-2 border-black border rounded-md d selected__data '></input>
                            <p className='text-sm text-red-500 mt-2'>{validator.email}</p>
                        </div>

                        <div className='mt-5'>
                            <label htmlFor="" className='region__section  capitalize'>password</label>
                            <input type={showpassword ? 'text' : 'password'}

                                onChange={(e) => setpassword(e.target.value)}
                                value={password}
                                className='font-control pl-2 mt-2 bg-gray-100 border-black border rounded-md d selected__data '></input>
                            <p><a href="" className='text-blue-700 mt-2'>Forgot password</a></p>
                        </div>

                        <div className='flex gap-3 justify-between mt-10'>

                            <Button
                                loading={loading}
                                onClick={submitform} className='w-full text___login hover:bg-blue-600 custome__btn_blue hover:text-white text-white bg-blue-800 py-3 rounded font-medium'>Login</Button>


                        </div>
                        <div className='flex justify-center mt-5 gap-3 items-center '>
                            <div className='line__sustain'></div>
                            <div>Or</div>
                            <div className='line__sustain'></div>
                        </div>

                        <div className='flex gap-4 mt-5'>
                            <div className='bg-gray-200 cursor-pointer flex justify-center items-center card__login_option'>
                                <img src={apple} className=' object-contain' />
                            </div>
                            <div className='bg-gray-200 cursor-pointer flex justify-center items-center card__login_option'>
                                <img src={meta} className=' object-contain' style={{ height: 70, width: 70 }} />
                            </div>
                            <div className='bg-gray-200 flex cursor-pointer justify-center items-center card__login_option'>
                                <img src={google} className=' object-contain' />
                            </div>
                        </div>

                        <div className='my-10'>
                            <p className='mt-4'>New to jabuh? <a href="/signup" className='text-blue-600'>Get Started <i className='fa-solid fa-arrow-right'></i> </a> </p>
                            <div className='flex mt-2 gap-3'>
                                <a href="" className='fonts__'>Help</a>
                                <a href="" className='fonts__'>Privacy</a>
                                <a href="" className='fonts__'>Terms</a>
                            </div>

                            <div></div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default Login
