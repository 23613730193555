import {
    createBrowserRouter, RouterProvider,
} from "react-router-dom";

import Home from "../pages/home";
import Products from "../pages/products";
import Viewpgae from "../pages/viewpage";

import Location from "../pages/stores/loginsteps/location";
import Signup from "../pages/stores/loginsteps/Signup";
import Userportal from "../pages/stores/userportal";
import Addproducts from "../pages/stores/products/addproduct";
import UserportalOverview from "../pages/stores/products/ProductOverview";
import PrivateRoutes from "../pages/auth/protectedRoutes/ProtectedRoutes";




const router = createBrowserRouter([
    { path: '/userportal', element: <PrivateRoutes /> },
    { path: '/userportal_addproduct', element: <Addproducts /> },
    { path: '/userportal_productoverview', element: <UserportalOverview /> },
    { path: '/users', element: <>loading</> },
]);

const ProtectedRoutes = () => <RouterProvider router={router} />

export default ProtectedRoutes;