import React, { useEffect, useRef, useState } from 'react';
import { useFilePicker } from 'use-file-picker';
import styled from 'styled-components';
import { Tree, Button, Select, Input } from 'antd';
import Inputs from '../../components/Inputs';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { connect } from 'react-redux';
import SidebarMenu from '../components/auth/Sidebar';
import { uploading_image } from '../../../bussinesslogic/functions';
import Cookies from 'js-cookie';

const Header = styled.div`
height: 50px;


`;
const Dasboard = styled.div``;
const Sidebarinner = styled.div`
width: 90%;


`;
const Sidebar = styled.div`
width: 250px;

@media(max-width: 48rem){
display: none;
}
`;
const MenuSidebar = styled.div`
span, i{
font-size: 14px;
}
`;

const MobileSection = styled.div`
height: 60px;
display: none;

@media(max-width: 48rem){
display: flex;
}

`;

const ContentDisplay = styled.div``;



const Addproducts = () => {
    // section  data here enough






    const n1 = localStorage.getItem('category') ? JSON.parse(localStorage.getItem('category')) : [];
    const n2 = localStorage.getItem('subcategory') ? JSON.parse(localStorage.getItem('subcategory')) : [];

    const [category, setcategory] = useState(n1);
    const [subcategory, setsubcategory] = useState(n1);



    useEffect(() => {
        axios.get('productcategory/index').then((res) => {
            localStorage.setItem('category', JSON.stringify(res.data.data));
            setcategory(res.data.data);
        });

        axios.get('productcategory/sub/index').then((res) => {
            setsubcategory(res.data.data);
            localStorage.setItem('subcategory', JSON.stringify(res.data.data));

        });
    }, [])







    const [loadding, setloading] = useState(false);

    const [price_f, setprice_f] = useState('0');
    const [costprice_f, setcostprice_f] = useState('0');

    const [price, setprice] = useState(0)
    const [costprice, setcostprice] = useState(0);
    const [margin, setmargin] = useState(0);
    const [profit_f, setprofit_f] = useState("");

    const { Option } = Select;

    const format_price_1 = (e) => {
        let value = e.target.value;
        console.log(value)
        value = value.replace(/,/g, '');
        value = value.replace(/\D/g, '');
        setprice(Number(value))
        value = Number(value).toLocaleString();
        setprice_f(value);

    }
    const format_price_2 = (e) => {
        let value = e.target.value;
        console.log(value)
        value = value.replace(/,/g, '');
        value = value.replace(/\D/g, '');
        const newvalue = Number(value)

        value = Number(value).toLocaleString();
        setcostprice_f(value);

        setcostprice(newvalue)

    }

    function calculate_proift() {
        const total = price - costprice;
        const percentage = total / 100;

        setprofit_f(total.toLocaleString());
        setmargin(percentage);


    }

    const navigate = useNavigate();


    //text formating

    const editorRef = useRef(null);
    const [content, setContent] = useState('');
    const [output, setoutput] = useState('');
    const handleinput = () => {
        setContent(editorRef.current.innerHTML);
    }




    const handlebold = (e, command) => {

        // console.log(selectedText)
        if (command == "bold") {
            e.currentTarget.classList.toggle('para_icons__active')
            if (!e.currentTarget.classList.contains('para_icons__active')) {

                setoutput(content);
                return
            }

            setoutput(<b>{content}</b>);
            return

        }
    }

    // adding event listeners
    const [showsubcategory, setshowsubcategory] = useState(false);
    const [showcategory, setshowcategory] = useState(false);
    const [textcategory, settextcategory] = useState('');
    const [textsubcategory, setsubtextcategory] = useState('');
    const [selectedsub_category_id, setselectedsub_category_id] = useState();

    const [selectedsubcategory, setselectedsubcategory] = useState([]);

    const keyid = (id) => {
        const filtereddata = subcategory.filter((sub) => sub.parentCategory === id);

        setselectedsubcategory(filtereddata);
    }
    // select
    const selectcategory = (cat) => {

        settextcategory(cat.name);
        setshowcategory(!showcategory);
        setshowsubcategory(false);
        setsubtextcategory('')
        keyid(cat.id);


    }

    const selectsubcategory = (subcat) => {

        setsubtextcategory(subcat.name)
        setselectedsub_category_id(subcat.id);
        console.log(subcat)
        console.log(subcat)
        setshowsubcategory(false)
        // setshowsubcategory(!showsubcategory);
        // setshowsubcategory(!showsubcategory)
    }


    //function uploafing image one  start 
    const fileInputRef = useRef(null);

    const [uploadimageresponse_status, setuploadimageresponse_status] = useState(null);
    const [uploadimageresponse, setuploadimageresponse] = useState(null);
    const { user } = JSON.parse(Cookies.get('user'));


    function handleuploadimage(event) {
        if (event.target.files && event.target.files[0]) {
            const selectedImage = event.target.files[0];
            const formdata = new FormData();
            formdata.append('image_url', selectedImage);
            formdata.append('user', user);



            axios.post('auth/user_image_url', formdata).then((response) => {
                if (response.status == 200) {

                    setuploadimageresponse_status(response.status);
                    setuploadimageresponse(response.data);
                }

            })

        }
    }
    //function upload image end
    function Uploadimage() {
        fileInputRef.current.click();
    }
    // adding product
    const [productname, setproductname] = useState('');
    const addingProduct = () => {
        const image_url = uploadimageresponse.path || ''
        const formdata = new FormData();
        formdata.append('name', productname);
        formdata.append('description', content);
        formdata.append('image', image_url);
        formdata.append('price', price);
        formdata.append('costprice', costprice);
        formdata.append('category', selectedsub_category_id);
        formdata.append('user', user);


        axios.post('auth/products/store', formdata).then((response) => {
            console.log(response)
        });


    }



    const [sidebar, setsidebar] = useState(false)

    return (

        <div className="header_portal__ui flex flex-col  h-screen">
            <Header className='flex justify-between items-center header_section_top_section_desktop bg-blue-900'>
                <div className="logo flex justify-center font_custome_Linque capitalize text-white font-extrabold items-center">
                    Jabuh
                </div>
                <div className="rounded-lg flex items-center justify-between  search_section addproduct_search">

                    <div className='ml-3 flex items-center gap-2'>
                        <i className='fa-regular fa-triangle-exclamation text-white'></i>

                        <div className='text-white'>Unsaved Product</div>
                    </div>
                    <div className='flex items-center gap-2 mr-3 '>
                        <div className='custome__shopproduct rounded-full cursor-pointer text-white'>Discard</div>
                        <div className='custome__shopproduct rounded-full cursor-pointer ' style={{ background: "white" }} onClick={addingProduct}>Save</div>
                    </div>

                </div>

                <div className=" flex items-center  gap-3 mr-4 user_option_components_avatar">
                    <div className=" hover:bg-gray-300 flex cursor-pointer u_highlights rounded-md">
                        <div className="icon_u relative p-1 px-2 ">

                            <div className="absolute bg-red-600 flex justify-center items-center rounded-full text-white font-medium icon_badge">
                                1
                            </div>

                            <i className='fa-regular fa-bell text-white' style={{ fontSize: 17 }}></i>
                        </div>


                    </div>

                    <div className="avatar cursor-pointer u_highlights  rounded-lg custome_ouliune flex px-1 py-1  items-center gap-2 ">
                        <div className="icon__shopdata rounded-lg bg-red-600 flex    justify-center items-center">
                            MS</div>
                        <span className='text-white font-medium'> My Store</span>
                    </div>
                </div>
            </Header>



            <MobileSection className='bg-blue-900 gap-3 items-center menu_dashbaord_mobile'>

                <div onClick={() => { setsidebar(!sidebar) }} className=' flex items-center justify-center cursor-pointer ml-2 mini___icon rounded-md'>
                    <i className='fa-regular fa-bars text-white ' style={{ fontSize: 22 }}></i>
                </div>

                <div className='flex rounded-md search__input___data '>
                    <div className="flex items-center justify-center icon__md">
                        <i className='fa-solid fa-search text-white' style={{ fontSize: 16 }}></i>
                    </div>
                    <input type="text" className='px-2' style={{ backgroundColor: 'transparent' }} />
                </div>

                <div className='flex'>
                    <div className="  icon__bell_mobile  mr-2 flex  justify-center items-center rounded-md">
                        <i className='fa-regular fa-bell text-white' style={{ fontSize: 20 }}></i>
                    </div>

                    <div className="icon__bell_mobile bg-red-600  mr-2 flex  justify-center items-center rounded-md">
                        MS
                    </div>
                </div>

                {sidebar && <div className="fixed sidebar__in shadow"></div>}

            </MobileSection>



            <Dasboard className='flex flex-1 dashboard_mobile '>
                <SidebarMenu />

                <ContentDisplay className='flex ContentDisplay flex-col  flex-1'>

                    <div className="container__wrapper_main_group">
                        <div className='mx-auto container__wrapper'>
                            <div className='flex mt-5  justify-between' >
                                <div className='font-bold add_product_text'> <i className='fa-regular fa-arrow-left' style={{ fontSize: 17 }}></i> Add Product</div>
                                <div className='p-2 bg-gray-200 view__container cursor-pointer  rounded-sm flex items-center justify-center'>View</div>
                            </div>

                            <div className='flex gap-4 w-full paragraph_container'>
                                <div className='w-full'>
                                    <div className='p-4 rounded-md bg-white w-full shadow'>
                                        <div className='form_group_section_wrap w-full'>
                                            <div className='lable_fonts_fonts '>Title</div>
                                            <Inputs
                                                onchange={(e) => setproductname(e.target.value)}
                                                inputplaceholder={false} styles={{ width: "100%" }}
                                                instyles={{ borderTopLeftRadius: "inherit", borderBottomLeftRadius: "inherit" }}
                                            />
                                        </div>


                                        <div className='form_group_section_wrap mt-5  w-full'>
                                            <div>
                                                <div className=' font__title lable_fonts_fonts '>Description</div>

                                                <div className='user_select_input flex flex-col textarea__'>

                                                    <div className='bg-gray-200 flex  control__data'>
                                                        <div className='flex items-center px-2 cursor-pointer '>Paragraph <div className='ml-2'>
                                                            <i className='fa-regular fa-chevron-down'></i>
                                                        </div> </div>
                                                        <div className='flex controls__group gap-2 items-center '>
                                                            <div className='para_icons  flex items-center justify-center  font-bold' onClick={(e) => handlebold(e, 'bold')}> B  </div>
                                                            <div className='para_icons  flex items-center justify-center italic'> <i className='fa-regular fa-italic'></i> </div>
                                                            <div className='para_icons  flex justify-center items-center '> <i className='fa-regular fa-underline'></i> </div>
                                                            <div className='para_icons '> <i className='fa-regular fa-A'></i></div>
                                                            <div className='para_icons  flex  items-center'>
                                                                <i class="fa-regular fa-align-left"></i>
                                                                <i class="fa-regular fa-chevron-down" style={{ fontSize: 12, marginLeft: 3 }}></i>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        ref={editorRef}
                                                        contentEditable={true}
                                                        suppressContentEditableWarning={true}
                                                        onInput={handleinput}
                                                        className='w-full outline-none text-wrap  border-none flex-1 text_area_fitint   rounded-md p-3 ' >
                                                        {output}

                                                    </div>




                                                </div>
                                            </div>

                                            <div className='mt-4'>
                                                <div className='lable_fonts_fonts font-semibold text-gray-700 '>media</div>

                                                {uploadimageresponse_status == 200 ? <div className='media__userSelect_image'>

                                                    <div className='border overflow-hidden border-black rounded-md main_image'>
                                                        <img src={uploadimageresponse.path} alt="" className='rounded-md' />
                                                    </div>

                                                </div> : <div className=" border bg-gray-100 rounded border-black flex flex-col justify-center items-center border-dashed image_collection_section">
                                                    <div>
                                                        <div className=" shadow-lg inline-block cursor-pointer upload__btn  rounded-md px-4  upload_new font-semibold border" onClick={Uploadimage}>Upload new</div>

                                                        <input
                                                            ref={fileInputRef}
                                                            className='hidden' type='file' accept='image/*' onChange={handleuploadimage} />
                                                    </div>
                                                    <p className='mt-3'>Accepts images, videos, or 3D models</p>
                                                </div>}



                                            </div>

                                            <div>

                                            </div>



                                            <div className='form_group_section_wrap relative mt-4 w-full'>
                                                <div className='lable_fonts_fonts '>Category</div>
                                                <Inputs inputplaceholder={false} styles={{ width: '100%' }}
                                                    value={textcategory}
                                                    onclick={() => {
                                                        setshowcategory(!showcategory)
                                                        setshowsubcategory(false)
                                                    }}
                                                    instyles={{ borderTopLeftRadius: "inherit", borderBottomLeftRadius: "inherit" }}

                                                />
                                                {showcategory && <div className='absolute__option_down p-2 border rounded shadow'>
                                                    {category.map((cat) => <div onClick={() => {
                                                        selectcategory(cat)
                                                    }} className='hover:bg-gray-300 cursor-pointer p-1 rounded-md content__grid_items'>{cat.name}</div>)}
                                                </div>}


                                                <div className='lable_fonts_fonts'>Determines tax rates and adds metafields to improve search, filters, and cross-channel sales</div>

                                            </div>
                                            <div className='form_group_section_wrap relative mt-4 w-full'>
                                                <div className='lable_fonts_fonts '>Sub Category</div>
                                                <Inputs inputplaceholder={false} styles={{ width: '100%' }}
                                                    value={textsubcategory}
                                                    onclick={() => {
                                                        setshowsubcategory(!showsubcategory);
                                                    }}
                                                    instyles={{ borderTopLeftRadius: "inherit", borderBottomLeftRadius: "inherit" }}

                                                />
                                                {showsubcategory && <div className=' absolute__option_down border rounded shadow'>
                                                    {selectedsubcategory.map((cat) => <div onClick={() => {
                                                        selectsubcategory(cat);
                                                    }} className='hover:bg-gray-300 cursor-pointer p-1 rounded-md content__grid_items'>{cat.name}</div>)}
                                                </div>}

                                                <div className='lable_fonts_fonts'>Select Sub category to use </div>

                                            </div>

                                        </div>









                                    </div>
                                    <div className='p-4 rounded-md mt-5 bg-white w-full shadow'>
                                        <div className='font-bold'>Pricing</div>

                                        <div className='flex mt-3'>
                                            <div className='form_group_section_wrap '>
                                                <div className='lable_fonts_fonts '> Price</div>

                                                <Inputs onchange={format_price_1} value={price_f} />

                                            </div>


                                        </div>

                                        <div className='flex mt-5 gap-4'>
                                            <div className='form_group_section_wrap w-full'>
                                                <div className='lable_fonts_fonts  '>Cost Price</div>
                                                <Inputs onchange={format_price_2} onkeyup={calculate_proift} value={costprice_f} />
                                            </div>
                                            <div className='form_group_section_wrap w-full'>
                                                <div className='lable_fonts_fonts  '> Profit</div>
                                                <Inputs onchange={format_price_2} disabled={true} value={profit_f} />
                                            </div>



                                            <div className='form_group_section_wrap w-full'>
                                                <div className='lable_fonts_fonts '>Margins</div>
                                                <Input type="text" className='border border-gray-500 rounded-md px-2 custome__input' value={margin + "%"} />
                                            </div>


                                        </div>
                                    </div>


                                </div>


                                <div className="price__content">
                                    <div className='shadow p-4 bg-white rounded  image__wrapper_content'>
                                        <div>Status</div>
                                        <div class="mt-3  flex items-center justify-center">
                                            <Select


                                                defaultValue='active'
                                                className='w-full custome__input' >
                                                <Option value='Active'>Active</Option>
                                                <Option value='Draft'>Draft</Option>

                                            </Select>


                                        </div>
                                    </div>
                                    <div className='shadow mt-4 p-4 bg-white rounded  image__wrapper_content'>
                                        <div>Status</div>
                                        <div class="mt-3  flex items-center justify-center images__outlined__group">
                                            <button>add Image</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContentDisplay>
            </Dasboard>

        </div >
    )
}

export default Addproducts
