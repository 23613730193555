import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import axios from 'axios'

const Header = styled.div`
height: 50px;


`;
const Dasboard = styled.div``;
const Sidebarinner = styled.div`
width: 90%;`;

const MenuSidebar = styled.div`
span, i{
font-size: 14px;
}
`;

const Sidebar = styled.div`
width: 250px;
@media(max-width: 48rem){
display: none;
}
`;


const SidebarMenu = () => {
    const navigate = useNavigate();
    return (
        <Sidebar className=' sidebar__dashabord'>
            <Sidebarinner className='pt-3 mx-auto'>
                <MenuSidebar onClick={() => {
                    navigate('/userportal_productoverview')
                }} className='flex gap-2 py-1 mt-2 items-center cursor-pointer rounded-md active__sidemneu transition_'>
                    <i className='fa-solid fa-home ml-3'></i>
                    <span className='font-medium '>Home</span>
                </MenuSidebar>
                <MenuSidebar
                    onClick={() => {

                    }}

                    className='flex mt-2 gap-2 py-1 items-center cursor-pointer rounded-md menu_sidebar  transition_'>
                    <i className='fa-solid fa-tag ml-3'></i>
                    <span className='font-medium '>Orders</span>
                </MenuSidebar>
                <MenuSidebar
                    onClick={() => {
                        navigate('/userportal_addproduct')
                    }}
                    className='flex mt-2 gap-2 py-1 items-center cursor-pointer rounded-md menu_sidebar  transition_'>
                    <i className='fa-solid fa-inbox ml-3'></i>
                    <span className='font-medium '>Product</span>
                </MenuSidebar>
            </Sidebarinner>
        </Sidebar>
    )
}

export default SidebarMenu
