import React, { useState } from 'react';
import logo from '../../../images/logo/logo.png';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';



const Signup = () => {
    const userTheme = useSelector(state => state.registertheme);
    const navigate = useNavigate();
    console.log(userTheme)
    const locations = ['bamenda', 'douala', 'limbe', 'mbambui'];
    const [email, setemail] = useState(userTheme.email || '');
    const [name, setname] = useState(userTheme.name || '');
    const [password, setpassword] = useState('');
    const [passwordconfirmation, setpasswordconfirmation] = useState('');
    const [error, seterror] = useState(false);
    const [success, setsuccess] = useState(false);
    const [errormessage, seterrormessage] = useState('Something went wrong');
    const [showpassword, setshowpassword] = useState(false);
    const [iconshow, seticonshow] = useState(<i className='fa-regular fa-eye'></i>);
    const [validator, setvalidator] = useState({});
    const [loading, setloading] = useState(false);

    const submitform = () => {
        seterror(false);
        setloading(true)
        const formdata = new FormData();
        formdata.append('email', email);
        formdata.append('password', password);
        formdata.append('name', name);
        formdata.append('location', userTheme.location);
        formdata.append('storename', 'myStore');
        formdata.append('password_confirmation', passwordconfirmation);

        axios.post('auth/userAuth_register', formdata).then((respond) => {
            if (respond.status == 201 || respond.status == 200) {
                setsuccess(true);
                setloading(false);
                setTimeout(() => {
                    navigate('/login');
                }, 2000)
            }
        }).catch((err) => {
            setloading(false)
            console.log(err)
            if (err.response.status == 400) {
                setvalidator(err.response.data.validator)
            }
            if (err.response.status == 401) {
                seterror(true);
                setsuccess(false)
                seterrormessage(err.response.data.message)
            }

            if (err.response.status == 500) {
                seterror(true);
                setsuccess(false)

                seterrormessage(err.response.data.message)
            }
            // seterrormessage(err.responce.message);
        });

    }


    return (
        <div className='flex bg-blue-300 h-screen justify-center items-center animate__section_bg'>
            <div className='bg-white shadow p-10 rounded-md mx-auto content_signUp '>
                <div className='content_signUp__innner mx-auto'>
                    {success && <div className='bg-green-500 text-center p-2 rounded-md text-white my-3'>
                        Account Created Successfully, please check your email to confirm <i className='fa-solid fa-check-circle ml-3'></i> </div>}


                    {error &&
                        <div className='bg-red-500 text-center p-2 rounded-md text-white my-3'>{errormessage}</div>}

                    <div className="flex items-center">
                        <div className="grid_logo">
                            <img src={logo} />
                        </div>
                        <div className='font-bold font__h2'>Jabuh</div>
                    </div>




                    <div className=' mt-4'>
                        <h1 className='font-bold font__h1_big line_height_h1 '>Create your Account to get Started </h1>
                        <p className='mt-3'>just enter your details to get started and enjoy the ease of selling your product</p>


                        <div className='mt-5'>
                            <label htmlFor="" className='region__section  capitalize'>email</label>
                            <input type="email"
                                onChange={(e) => setemail(e.target.value)}
                                value={email}
                                className='font-control pl-2 bg-gray-100 mt-2 border-black border rounded-md d selected__data capitalize'></input>
                            <p className='text-sm text-red-500 mt-2'>{validator.email}</p>
                        </div>
                        <div className='mt-5'>
                            <label htmlFor="" className='region__section  capitalize'>Full Name</label>
                            <input type="text"
                                onChange={(e) => setname(e.target.value)}
                                value={name}
                                className='font-control pl-2 bg-gray-100 mt-2 border-black border rounded-md d selected__data capitalize'></input>

                            <p className='text-sm text-red-500 mt-2'>{validator.name}</p>
                        </div>

                        <div className='mt-5'>
                            <label htmlFor="" className='region__section  capitalize'>password</label>
                            <div className='flex items-center gap-2 border-black rounded-md border bg-gray-100 selected__data '>
                                <input type={showpassword ? 'text' : 'password'}
                                    onChange={(e) => setpassword(e.target.value)}
                                    value={password}
                                    className='font-control pl-2 outline-none h-full w-full   rounded-md d  capitalize'></input>
                                <div className='icon_see flex' onClick={() => setshowpassword(!showpassword)}>{iconshow}</div>
                            </div>
                            <p className='text-sm text-red-500 mt-2'>{validator.password}</p>
                        </div>
                        <div className='mt-5'>
                            <label htmlFor="" className='region__section  capitalize'>Confirm password</label>
                            <input type={showpassword ? 'text' : 'password'}

                                onChange={(e) => setpasswordconfirmation(e.target.value)}
                                value={passwordconfirmation}
                                className='font-control pl-2 mt-2 bg-gray-100 border-black border rounded-md d selected__data capitalize'></input>
                        </div>

                        <div className='flex gap-3 justify-between mt-10'>

                            <Button
                                loading={loading}
                                onClick={submitform} className='w-full text___login hover:bg-blue-600 custome__btn_blue hover:text-white text-white bg-blue-800 py-3 rounded font-medium'>Create your jabuh account</Button>


                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default Signup
