import React from 'react'
import styled from 'styled-components'



function Inputs({ onchange, value, inputplaceholder = true, styles, instyles, disabled = false, onkeyup, onclick }) {
    const bordoutline = (e) => {
        e.currentTarget.classList.add('selected_border')
        e.currentTarget.classList.add('shadow')
        e.currentTarget.classList.add('border-blue-500')
        console.log()
    }
    return (
        <div className='border-black flex myInput rounded-md' onClick={bordoutline} style={styles}>
            {inputplaceholder && <div className='flex items-center justify-center place_holder_text text-center'>
                FCFA
            </div>}
            <input
                style={instyles}
                onKeyUp={onkeyup}
                onClick={onclick}
                type="text" className='pl-2' onChange={onchange} value={value} disabled={disabled} />
        </div>
    )
}

export default Inputs