import React, { useEffect, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';

import Index from '../../layout/components/Navigation'
import { Container } from '../../layout/components/styles'
import MyCard from '../../layout/components/store/SubcatTitle'
import Cartone from '../../layout/components/products/cartone';
import styled from 'styled-components';
import Footer from '../../layout/components/footer/footer';
import img1 from '../../images/demo/image2.png';
import noimage from "../../images/logo/noimage.jpg"
import axios from 'axios';
import { useParams } from 'react-router-dom';




const Splitter = styled.div`


`;

const SkeletonDemo = styled.div``;
const Tittle_captions = ({ caption, text }) => {

    return <div className="flex justify-center flex-col rounded-md  p-2 px-4 bg-gray-200 py-1 square_boxes">
        <div className='card__price_caption  text__gray__100'>{caption}</div>
        <div className='font___price mt-1 font-semibold'>{text}</div>
    </div>
}

function shortern(text = "simon is a new born") {
    const words = text.split(" ");


    if (words.length > 2) {

        return words.slice(0, 2).join(" ") + " ...";
    }

    // If there are two or fewer words, return the text as is
    return text;

}





const Products = () => {
    const { id } = useParams();
    const newvalue = id.split('_').join(' ').toLowerCase();

    const [loading, setloading] = useState(false);

    const [products, setproducts] = useState([]);


    useEffect(() => {
        axios.get('/products').then((response) => {
            var results = response.data.data.filter((product) => product.subcategory.name.toLowerCase() == newvalue);

            setproducts(results);
            setloading(true)

        }).catch((err) => {
            console.log(err)
        })

    }, [])
    return <div>
        <Index />
        <Container style={{ width: '70%' }} >
            <div className='min__scroll__bar'>

            </div>
            <div className='flex flex-wrap gap-2 product__container__wrapper'>

                {
                    products.map((product) => (
                        <>
                            {console.log(product)}
                            <Cartone name={product.name} image={product.image}
                                manufaturer={shortern(product.user.name)}
                                price={"CFA " + Number(product.price).toLocaleString()}

                                category={shortern(product.subcategory.name)} />
                        </>
                    ))


                }

                {loading === true && products.length === 0 && <p className='p-4 capitalize'>Nothing Post Found Under Category </p>}


            </div>
        </Container>

    </div>

}


export default Products
