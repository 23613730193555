import React from 'react'
import img1 from '../../../images/demo/image1.png'

const Cartone = ({ image = "https://images.unsplash.com/photo-1725656470814-df6508fea03c?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHw1N3x8fGVufDB8fHx8fA%3D%3D", price, rating, manufaturer, category, created_on, name, location }) => {
    return (
        <div className='   mt-10   new__product_card'>
            <div className="image__wrapper border shadow bg-white ">
                <img src={image} />
            </div>

            <div className='my-3 card__description'>
                <div className='flex my-2 gap-1 rating__group'>
                    <div className=" flex gap-2 seller_rating">
                        <i className='fa-regular fa-star'></i>
                        <i className='fa-regular fa-star'></i>
                        <i className='fa-regular fa-star'></i>
                        <i className='fa-regular fa-star'></i>
                        <i className='fa-regular fa-star'></i>
                    </div>
                    <div className='capitalize manufacturer  text-red-700'>
                        by <span >{manufaturer}</span>
                    </div>
                </div>

                <div className='text-left name_description font-medium capitalize'>{name}   </div>
                <div className='price__section font-bold mt-2 '>{price}</div>
                <div className='category bg-gray-200 inline-block rounded p-1 px-2 mt-2 text-red-700'>{category}</div>
                <div className='location__category text-gray-600 mt-3 '> {created_on}. {location}</div>
                <div className='p-2 rounded-full border border-gray-500 flex mt-4 hover:bg-gray-200 cursor-pointer justify-center contact__supplier'>Contact Supplier</div>
            </div>

        </div>
    )
}

export default Cartone
